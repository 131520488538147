
import { defineComponent, onMounted, getCurrentInstance, computed, ref } from 'vue';
import cookies from 'js-cookie';
import Popup from 'vant/lib/popup';
import PwaFeed from '@/pages/pwa-share-new/index.vue';
import { getTargetUrlFromWebOrDesktop, commonPvParams } from '@/common/utils';
import { immediateSendLog, reportFMP, getKwaiLog } from '@/common/radar';
import { header } from '@/common/seo';
import { setCommonParamsCookiesInServer } from '@/common/common-params';
import { FeedPageEnum } from '@/store/modules/feed';
import { ComponentNameMap } from '@/constants/base';
import device from '@/common/device-info';

const Foryou = defineComponent({
    name: ComponentNameMap.Foryou,
    components: {
        PwaFeed,
        Popup,
    },
    layout(ctx) {
        return ctx.$pwaLayout || 'default';
    },
    setup() {
        const { proxy } = getCurrentInstance() || {};
        const visible = ref(false);
        const images = [
            'https://cdn-static.kwai.net/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/popup-avator-1.png',
            'https://cdn-static.kwai.net/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/popup-avator-2.png',
            'https://cdn-static.kwai.net/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/popup-avator-3.png',
        ];
        const rentention_background = 'https://cdn-static.kwai.net/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/retention-background.png';

        const sendPv = () => {
            const params = commonPvParams({ defaultPageSource: 'foryou' });
            const country = proxy?.$store?.state?.startup?.countryInfo;
            const user_id = cookies.get('user_id');

            immediateSendLog({
                type: 'pv',
                name: 'SHARE_PWA_PAGE_H5',
                value: {
                    country,
                    user_id,
                    ...params,
                    ...proxy?.$route.query,
                },
            });
        };

        const handleCancel = () => {
            visible.value = false;
        };

        const openGooglePlay = () => {
            const currentParams = window.location.search;
            const url = `https://play.google.com/store/apps/details?id=com.kwai.video&listing=referral&${currentParams.slice(1)}`;
            window.open(url);
        };

        const checkRententionPopupConditions = () => {
            const url = window?.location?.href;
            const params = new URLSearchParams(new URL(url).search);
            const pwa_source = params.get('pwa_source');
            if (pwa_source && pwa_source.startsWith('directGP_')) {
                visible.value = true;
            }
        };

        onMounted(() => {
            reportFMP();
            sendPv();
            const webLogger = getKwaiLog();
            // 自定义性能指标上报
            if (proxy?.$store?.state?.abTestModule?.abTestParams?.cache_exp) {
                webLogger?.plugins?.radar?.customStage('custom_fmp_cache');
            } else {
                webLogger?.plugins?.radar?.customStage('custom_fmp');
            }
            checkRententionPopupConditions();
        });

        return {
            FeedPageEnum,
            visible,
            images,
            handleCancel,
            openGooglePlay,
            rentention_background,
        };
    },

    async asyncData(ctx) {
        if (process.server) {
            const { startup } = ctx?.store?.state || {};
            setCommonParamsCookiesInServer({
                ctx,
                bucket: startup?.bucket,
                host: ctx.req.headers.host,
                countryInfo: startup?.countryInfo,
            });
        }

        return {
            metaInfoData: [],
        };
    },

    head() {
        const head = header(this);
        return head;
    },
});

export default Foryou;
