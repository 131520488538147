
import { defineComponent } from 'vue';
import Foryou from '@/pages/foryou/index.vue';

const RootIndex = defineComponent({
    components: {
        Foryou,
    },
    layout(ctx) {
        return ctx.$pwaLayout || 'default';
    },
});

export default RootIndex;
